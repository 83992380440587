<template>
  <gg-card>
    <v-row>
      <v-col cols="12" md="4">
        <v-img :src="require('@/assets/img/pexels-anna-shvets-3683056.jpg')" />
      </v-col>
      <v-col cols="12" md="8">
        <validation-observer ref="validator">
          <sp-text-field
            v-model="hcNumber"
            rules="required"
            label="Health Card Number"
            placeholder="XXXX-XXX-XXX"
            v-mask="'####-###-###'"
            name="hcNumber"
          />
          <sp-text-field
            v-model="hcVersion"
            rules="required"
            label="2-letter version code"
            placeholder="AA"
            v-mask="'AA'"
            name="hcVersion"
          />
          <sp-text-field
            v-model="hcCode"
            rules="required"
            label="9-Character Code on Back of Health Card"
            placeholder="XXXXXXXXX"
            v-mask="'XXXXXXXXX'"
            name="hcCode"
          />
          <sp-text-field
            v-model="dateOfBirth"
            rules="required|date:yyyy-MM-dd"
            label="Date of Birth"
            placeholder="YYYY-MM-DD"
            v-mask="'####-##-##'"
            name="hcCode"
          />
          <div class="d-flex flex-column flex-md-row">
            <file-upload rules="required" name="front">
              Front Health Card
            </file-upload>
            <file-upload class="ml-md-5 my-4 my-md-0" rules="required" name="back">
              Back Health Card
            </file-upload>
          </div>
        </validation-observer>
      </v-col>
    </v-row>
    <v-card-actions class="justify-content-end">
      <sp-btn secondary @click="skip(2)">Skip this for now</sp-btn>
      <sp-btn @click="setStep(2)">Save</sp-btn>
    </v-card-actions>
  </gg-card>
</template>

<script>
import FileUpload from '@/components/Common/FileUpload'

export default {
  name: 'HealthCard',
  components: {
    FileUpload
  },
  data () {
    return {
      hcNumber: '', // health card number
      hcVersion: '',
      hcCode: '',
      dateOfBirth: ''
    }
  },
  methods: {
    async setStep (val) {
      const valid = await this.$refs.validator.validate()
      if (!valid) {
        return
      }
      this.$emit('switch-step', val)
    },
    skip (val) {
      this.$emit('switch-step', val)
    }
  }
}
</script>
