import amapi from '@/api/api'

const state = {
  step: 0,
  selectedOption: undefined,
  prescriptionCompleted: false,
  prescriptionsList: []
}

// getters
const getters = {
  step: state => state.step,
  selectedOption: state => state.selectedOption,
  prescriptionCompleted: state => state.prescriptionCompleted,
  prescriptionsList: state => state.prescriptionsList
}

const mutations = {
  SET_STEP (state, step) {
    state.step = step
  },
  SELECT_PRESCRIPTION_TYPE (state, payload) {
    state.selectedOption = payload
  },
  SET_PRESCRIPTION_COMPLETED (state, completed) {
    state.prescriptionCompleted = completed
  },
  SET_PRESCRIPTION_LIST (state, payload) {
    state.prescriptionsList = payload
  }
}

// actions
const actions = {
  async findPrescriptions ({ commit }, query) {
    // eslint-disable-next-line no-useless-catch
    try {
      const { pharmacies } = await amapi.makecall('findpharmacy', { q: query })

      commit('SET_PRESCRIPTION_LIST', pharmacies)

      return pharmacies
    } catch (e) {
      throw e
    }
  }
  // reportLogout ({ commit }, msg) {
  //   // console.log(msg)
  //   Notification.notify({
  //     verticalAlign: 'top',
  //     horizontalAlign: 'right',
  //     type: 'danger',
  //     timeout: 0,
  //     message: msg.toString()
  //   })
  // }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
