<template>
  <v-card v-bind="$attrs">
    <slot></slot>
  </v-card>
</template>

<script>
export default {
  name: 'GGCard'
}
</script>
