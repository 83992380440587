<template>
  <v-app id="sp-app">
    <router-view></router-view>
    <notifications :max="3" width="340">
      <template slot="body" slot-scope="{ item, close }">
        <v-alert
          :type="item.type"
          outlined
          text
          border="right"
          class="ma-1 notification-alert"
          @click="close"
        >
          {{ item.text }}
        </v-alert>
      </template>
    </notifications>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    //
  })
}
</script>

<style>
* {
  font-family: 'Noto Sans', sans-serif;
}
</style>
