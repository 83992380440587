import AdminLayout from '@/container/Admin'
import AdminHome from '@/views/admin/Home'
import PendingPrescriptions from '@/views/admin/PendingPrescriptions'
import PendingPrescription from '@/views/admin/PendingPrescriptionView'

export default [
  {
    path: '/admin',
    component: AdminLayout,
    children: [
      {
        path: '/',
        name: 'admin-dashboard',
        component: AdminHome,
        meta: {
          accessType: 'auth'
        }
      },
      {
        path: 'prescriptions',
        name: 'admin-pending-prescriptions',
        component: PendingPrescriptions,
        meta: {
          accessType: 'auth'
        }
      },
      {
        path: 'prescription/:id',
        name: 'admin-pending-prescription',
        component: PendingPrescription,
        meta: {
          accessType: 'auth'
        }
      }
    ]
  }
]
