<template>
  <v-container class="px-6 pl-md-10" style="margin: initial">
    <v-row>
      <v-col cols="12">
        <h3 class="font-weight-medium pb-4">
          Pending Prescription Orders
        </h3>
      </v-col>
      <v-col cols="12">
        <pending-prescriptions-list />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PendingPrescriptionsList from '@/components/Admin/Prescriptions/PendingPrescriptionsList'

export default {
  name: 'PendingPrescriptions',
  components: {
    PendingPrescriptionsList
  }
}
</script>

<style scoped>

</style>
