const state = {
  pendingPrescriptions: [
    {
      orderId: '1111',
      orderType: 'New',
      orderSource: 'Website',
      name: 'Aliquam volutpat ullamcorper',
      phone: '+1234567890'
    },
    {
      orderId: '2222',
      orderType: 'Refill',
      orderSource: 'Website',
      name: 'Scelerisque quis ipsum',
      phone: '+10987654321'
    }
  ]
}

const getters = {
  pendingPrescriptions: state => state.pendingPrescriptions
}

const mutations = {
  SET_PENDING_PRESCRIPTIONS (state, prescriptions) {
    state.pendingPrescriptions = prescriptions
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
