<template>
  <v-container fluid class="px-6">
    <v-row>
      <v-col cols="12">
        Admin Home
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Home'
}
</script>
