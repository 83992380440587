<template>
  <v-container class="px-6" style="margin: initial">
    <v-row>
      <v-col cols="12">
        <h3 class="font-weight-medium">
          Your Prescriptions History
        </h3>
      </v-col>
      <v-col cols="12">
        <prescriptions-history />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PrescriptionsHistory from '@/components/History/PrescriptionsHistory'

export default {
  name: 'Prescriptions',
  components: {
    PrescriptionsHistory
  }
}
</script>

<style scoped>

</style>
