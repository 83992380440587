<template>
  <div class="upload-item text-center" v-if="!imageData">
    <label :for="`upload-${name}`">
      <v-icon size="40">mdi-cloud-upload</v-icon>
      <div class="font-sm">
        <slot />
      </div>
    </label>
    <validation-provider
      tag="div"
      :vid="vid"
      :rules="rules"
      :name="name || label"
      v-slot="{ errors, ariaMsg, validate }"
    >
      <input type="file" :id="`upload-${name}`" :name="name" ref="fileInput" @change="validate" @input="onSelectFile">
      <span
        class="block red--text text--lighten-1"
        v-bind="ariaMsg"
        v-if="errors[0]"
      >{{ errors[0] }}</span>
    </validation-provider>
  </div>
  <div v-else class="img-preview" :style="{ 'background-image': `url(${imageData})` }">
    <v-btn icon @click="removeImage">
      <v-icon color="red">mdi-delete-outline</v-icon>
    </v-btn>
  </div>
</template>

<script>

export default {
  name: 'FileUpload',
  props: {
    vid: {
      type: String,
      default: undefined
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    rules: {
      type: [Object, String],
      default: ''
    }
  },
  data: () => ({
    imageData: null
  }),
  methods: {
    onSelectFile () {
      const input = this.$refs.fileInput
      const files = input.files
      if (files && files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.imageData = e.target.result
        }
        reader.readAsDataURL(files[0])
        this.imageData = files[0]
        this.$emit('input', files[0])
      }
    },
    removeImage () {
      this.imageData = null
      this.$emit('remove')
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-item {
  input[type='file'] {
    display: none;
  }

  label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dashed #00A1BB;
    width: 200px;
    height: 200px;
    user-select: none;
    cursor: pointer;
  }
}

.img-preview {
  display: flex;
  justify-content: end;
  align-items: self-start;
  border: 1px dashed #00A1BB;
  width: 200px;
  height: 200px;
  user-select: none;
  background-position: center;
  background-size: cover;
}

</style>
