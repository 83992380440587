<template>
  <gg-card>
    <v-row>
      <v-col cols="12" md="4">
        <v-img :src="require('@/assets/img/pexels-anna-shvets-3683056.jpg')" />
      </v-col>
      <v-col cols="12" md="8">
        <validation-observer ref="validator">
          <v-radio-group label="Health Insurance" v-model="haveInsurance">
            <v-radio label="I have Health Insurance" :value="true"></v-radio>
            <v-radio label="I do not have Health Insurance " :value="false"></v-radio>
          </v-radio-group>
          <div v-if="haveInsurance" class="d-flex flex-column flex-md-row">
            <file-upload name="front-ins" rules="required">
              Front
            </file-upload>
            <file-upload name="back-ins" rules="required" class="ml-md-5 my-4 my-md-0">
              Back
            </file-upload>
          </div>
        </validation-observer>
      </v-col>
    </v-row>
    <v-card-actions class="justify-content-end">
      <sp-btn secondary @click="setStep(1)">Back</sp-btn>
      <sp-btn secondary @click="setStep(3)">Skip this for now</sp-btn>
      <sp-btn @click="save">Save</sp-btn>
    </v-card-actions>
  </gg-card>
</template>

<script>
import FileUpload from '@/components/Common/FileUpload'

export default {
  name: 'InsuranceInformation',
  components: {
    FileUpload
  },
  data () {
    return {
      haveInsurance: true
    }
  },
  methods: {
    setStep (val) {
      this.$emit('switch-step', val)
    },
    async save () {
      const valid = await this.$refs.validator.validate()
      if (!valid) {
        return
      }
      this.setStep(3)
    }
  }
}
</script>

<style scoped>

</style>
