<template functional>
  <label :class="{ 'd-block': true, 'pb-1': true, 'red--text text--darken-1': props.error }">
    <slot></slot>
  </label>
</template>

<script>
export default {
  props: {
    error: {
      type: Boolean,
      required: false
    }
  }
}
</script>
