<template>
  <gg-card>
    <gg-card-header>
      Complete Profile
    </gg-card-header>
    <gg-card-text>
      <v-stepper :alt-labels="!$vuetify.breakpoint.xs" v-model="step">
        <v-stepper-header>
          <v-stepper-step step="1">
            <div class="text-center">Health Card</div>
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="2">
            <div class="text-center">Insurance Information</div>
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3">
            <div class="text-center">Address Details</div>
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="4">
            <div class="text-center">Payment Method</div>
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="5">
            <div class="text-center">
              Health Information
            </div>
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <health-card @switch-step="changeStep" />
          </v-stepper-content>
          <v-stepper-content step="2">
            <insurance-information @switch-step="changeStep" />
          </v-stepper-content>
          <v-stepper-content step="3">
            <address-card @switch-step="changeStep" />
          </v-stepper-content>
          <v-stepper-content step="4">
            <payment-method @switch-step="changeStep" />
          </v-stepper-content>
          <v-stepper-content step="5">
            <health-information @switch-step="changeStep" />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </gg-card-text>
  </gg-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import HealthCard from '@/components/CompleteProfile/Items/HealthCard'
import InsuranceInformation from '@/components/CompleteProfile/Items/InsuranceInformation'
import AddressCard from '@/components/CompleteProfile/Items/AddressCard'
import PaymentMethod from '@/components/CompleteProfile/Items/PaymentMethod'
import HealthInformation from '@/components/CompleteProfile/Items/HealthInformation'

export default {
  name: 'CompleteProfile',
  components: {
    HealthInformation,
    HealthCard,
    InsuranceInformation,
    AddressCard,
    PaymentMethod
  },
  computed: {
    ...mapGetters({
      stepGetter: 'profile/step'
    }),
    step: {
      get () {
        return this.stepGetter
      },
      set (val) {
        this.setStep(val)
      }
    }
  },
  methods: {
    ...mapMutations({
      setStep: 'profile/SET_STEP'
    }),
    changeStep (val) {
      this.step = val
    }
  }
}
</script>

<style scoped>

</style>
