<template>
  <v-list-item class="sidebar-profile">
    <v-menu
      bottom
      offset-y
      left
      content-class="userblock-dropdown"
      nudge-top="-10"
      nudge-right="0"
      transition="slide-y-transition"
      min-width="225"
      :close-on-content-click="false"
      v-model="menu"
    >
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <span class="hidden-sm-and-down display-ib pr-2 v-align--bottom" style="text-align:right;">
            <span class="white--text fs-14 d-block">
              ID-1 - John Doe
            </span>
            <span class="white--text fs-12 fw-light d-block">
              example@example.com
            </span>
          </span>
          <v-avatar size="42">
            <img src="https://www.w3schools.com/howto/img_avatar.png"  alt="avatar"/>
          </v-avatar>
          <v-icon color="white">mdi-chevron-down</v-icon>
        </div>
      </template>
      <div class="dropdown-content">
        <div class="dropdown-top white--text text-right">
          <span class="white--text fs-14 font-weight-medium d-block">
            John Doe
          </span>
          <span class="d-block fs-12 fw-light">example@example.com</span>
          <span class="d-block fs-14 fw-light">
            <b class="font-weight-medium">Customer ID:</b> 1
          </span>
<!--          <div class="text-right">-->
<!--            <v-switch v-model="$vuetify.theme.dark"-->
<!--                      class="theme-switcher mt-1 d-inline-block"-->
<!--                      color="white"-->
<!--                      hide-details-->
<!--                      label="Dark Mode"-->
<!--                      @change="switchTheme(false)"-->
<!--            />-->
<!--          </div>-->
        </div>
        <v-list class="dropdown-list user-menu" @click.native="closeMenu">
          <v-list-item :to="{ name: 'profile' }">
            <v-icon small class="mr-3 primary--text">mdi-account</v-icon>
            <span>Manage Profile</span>
          </v-list-item>
          <v-list-item @click="tryLogout">
            <v-icon small class="mr-3 error--text">mdi-power</v-icon>
            <span>Logout</span>
          </v-list-item>
        </v-list>
      </div>
    </v-menu>
  </v-list-item>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      loadingToken: false,
      menu: false
    }
  },
  methods: {
    ...mapActions({
      logout: 'session/logout'
    }),
    switchTheme (changeModel) {
      if (changeModel === true) {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      }
      const theme = this.$vuetify.theme.dark ? 'dark' : 'light'
      localStorage.setItem('theme', theme)
    },
    closeMenu () {
      this.menu = false
    },
    tryLogout () {
      this.logout()
      this.$router.push({ name: 'login' })
    }
  }
}
</script>

<style scoped>
  .sidebar-profile {
    cursor: pointer;
  }

  .max-width-80 {
    max-width: 80px;
  }
</style>
