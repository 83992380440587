import { store } from '@/store'

// Sidebar Routers
export const mainMenu = [
  {
    action: 'mdi-view-dashboard',
    title: 'Dashboard',
    exact: true,
    to: { name: 'dashboard' }
  },
  {
    action: 'mdi-view-dashboard',
    title: 'My Prescriptions',
    exact: true,
    to: { name: 'prescriptions' }
  },
  {
    type: 'menu',
    title: 'Admin Area',
    action: 'mdi-view-dashboard',
    items: [
      {
        title: 'Pending Prescriptions',
        name: 'admin-area',
        to: 'admin-pending-prescriptions',
        exact: true
      }
    ]
  },
  {
    title: 'Get Help',
    action: 'mdi-lifebuoy',
    onClick () {
      store.commit('profile/SET_HELP_DIALOG', true)
    }
  }
]
