<template>
  <gg-card>
    <gg-card-header>Patient Profile</gg-card-header>
    <gg-card-text>
      <card-line label="Name">
        John Doe
      </card-line>
      <card-line label="Street Address 1">
        123 Address 1
      </card-line>
      <card-line label="Street Address 2">
        Suite 2
      </card-line>
      <card-line label="City, Province, Postal Code" :two-line="true">
        Toronto, ON L4L 5T4
      </card-line>
      <card-line label="Health Card" :two-line="true">
        1234-567-890-XX
      </card-line>
      <card-line label="Payment Method" :two-line="true" >
        VISA ***1234
      </card-line>
    </gg-card-text>
  </gg-card>
</template>

<script>
export default {
  name: 'PatientProfile'
}
</script>
