/**
 * Settings Module
 */
// import { Notification } from 'vue-notifyjs'

const state = {
  config: {
    theme: {
      light: {
        primary: '#00A1BB',
        ggprimary: '#3db14e',
        secondary: '#424242',
        accent: '#00A1BB',
        error: '#FF3739',
        info: '#2196F3',
        success: '#0CA750',
        warning: '#FFB70F',
        warning_alert: '#ff9900',
        header: '#002838',
        grey: '#E7E7E7',
        bg_grey: '#F0F0F0',
        bt_grey: '#ececec',
        btn_primary: '#1887bf'
      },
      dark: {
        primary: '#00A1BB',
        header: '#12171f',
        btn_primary: '#1887bf',
        accent: '#0CA750'
      },
      client: {
        sidebar: '#1887bf',
        content: '#f7f9fc'
      },
      admin: {
        sidebar: '#69737C',
        content: '#f7f9fc'
      }
    },
    routerAnimation: 'fadeIn',
    brand: {
      appLogo: require('@/assets/img/logo.png'), // App Logo,
      darkLogo: require('@/assets/img/logo-dark.png'), // dark logo
      brand: 'GreenGeeks', // Brand Name
      copyrightText: 'GreenGeeks © 2018 All Rights Reserved.' // Copyright Text
    },
    pagination: [25, 50, 100],
    itemsPerPage: 25
  },
  apiVersion: 0,
  outdatedCacheModal: false
}

// getters
const getters = {
  theme: state => state.config.theme,
  routerAnimation: state => state.config.routerAnimation,
  brand: state => state.config.brand,
  pagination: state => state.config.pagination,
  itemsPerPage: state => state.config.itemsPerPage,
  notify: state => state.notify,
  apiVersion: state => state.apiVersion,
  outdatedCacheModal: state => state.outdatedCacheModal
}

const mutations = {}

// actions
const actions = {
  // reportSuccess () {
  //   Notification.notify({
  //     verticalAlign: 'top',
  //     horizontalAlign: 'right',
  //     type: 'success',
  //     timeout: 5000,
  //     message: arguments[1].toString()
  //   })
  // },
  // reportError () {
  //   let msg
  //   if (typeof arguments[1] !== 'object') {
  //     msg = arguments[1]
  //   } else {
  //     msg = arguments[1].message.toString()
  //   }
  //
  //   Notification.notify({
  //     verticalAlign: 'top',
  //     horizontalAlign: 'right',
  //     type: 'danger',
  //     timeout: 10000,
  //     message: msg
  //   })
  // },
  // reportLogout ({ commit }, msg) {
  //   // console.log(msg)
  //   Notification.notify({
  //     verticalAlign: 'top',
  //     horizontalAlign: 'right',
  //     type: 'danger',
  //     timeout: 0,
  //     message: msg.toString()
  //   })
  // }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
