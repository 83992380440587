<template>
  <v-card-text>
    <slot></slot>
  </v-card-text>
</template>

<script>
export default {
  name: 'GGCardText'
}
</script>
