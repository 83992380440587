<template>
  <v-container class="px-6 pl-md-10" style="margin: initial">
    <v-row>
      <v-col>
        <patient-dashboard />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import {
// mapGetters
// mapMutations
// } from 'vuex'
import PatientDashboard from '@/components/Client/PatientDashboard'

export default {
  name: 'Home',
  components: {
    PatientDashboard
  }
  // computed: {
  // ...mapGetters({
  // step: 'prescription/step',
  // selectedOption: 'prescription/selectedOption',
  // prescriptionCompleted: 'prescription/prescriptionCompleted',
  // firstLogin: 'profile/firstLogin',
  // theme: 'settings/theme'
  // })
  // componentType () {
  //   switch (this.selectedOption) {
  //     case 'transfer':
  //       return () => import('@/components/Prescriptions/PrescriptionItems/Transfer')
  //     case 'refill':
  //       return () => import('@/components/Prescriptions/PrescriptionItems/Refill')
  //     case 'new':
  //       return () => import('@/components/Prescriptions/PrescriptionItems/New')
  //
  //     default:
  //       return ''
  //   }
  // }
  // },
  // methods: {
  // ...mapMutations({
  //   setStep: 'prescription/SET_STEP'
  // }),
  // back () {
  //   this.setStep(0)
  // }
  // }
}
</script>

<style lang="scss">
</style>
