<template>
  <validation-provider :name="$attrs.label" :rules="rules" v-slot="{ errors, valid }" tag="div">
    <gg-input-label v-if="label" class="d-block pb-1">
      {{ label }}
    </gg-input-label>
    <v-text-field
      v-model="innerValue"
      outlined
      dense
      :error-messages="errors"
      :success="valid"
      v-bind="$attrs"
      v-on="$listeners"
    ></v-text-field>
  </validation-provider>
</template>

<script>
export default {
  name: 'TextField',
  props: {
    rules: {
      type: [Object, String],
      default: ''
    },
    // must be included in props
    value: {
      type: null
    },
    label: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    innerValue: ''
  }),
  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      this.$emit('input', newVal)
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal
    }
  },
  created () {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
</script>
