<template>
  <gg-card>
    <gg-card-header>
      Insurance Information
    </gg-card-header>
    <gg-card-text></gg-card-text>
  </gg-card>
</template>

<script>
export default {
  name: 'InsuranceInformation'
}
</script>
