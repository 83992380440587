<template>
  <div class="app-default-layout">
    <template>
      <app-header :colors="theme.client" />
      <v-main evelation="0" class="fill-height">
        <router-view />
      </v-main>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Header from '@/components/General/Header/Header.vue'

export default {
  name: 'ClientLayout',
  data () {
    return {
      loading: true
    }
  },
  components: {
    appHeader: Header
    // Preloader
  },
  computed: {
    ...mapGetters({
      theme: 'settings/theme'
    })
  }
}
</script>

<style>
.app-default-layout {
  height: 100vh;
}
.v-btn--contained {
  box-shadow: none !important; /** AM-695 remove btn shadow */
}
</style>
