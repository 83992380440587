<template>
  <gg-card class="mb-10">
    <gg-card-header>
      {{ item.header }}
      <v-chip class="float-right">{{ item.status }}</v-chip>
    </gg-card-header>
    <gg-card-text>
      <div class="text-sm">
        {{ item.date }}
      </div>

      <div class="text-md font-weight-bold pt-10">
        Prescription ID: {{ item.id }}
      </div>
    </gg-card-text>
  </gg-card>
</template>

<script>
export default {
  name: 'PrescriptionItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
