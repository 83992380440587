<template>
  <div>
    <prescription-item v-for="(item, i) in history" :key="i" :item="item" />
  </div>
</template>

<script>
import PrescriptionItem from '@/components/History/PrescriptionItem'

export default {
  name: 'PrescriptionsHistory',
  components: {
    PrescriptionItem
  },
  data: () => ({
    history: [
      {
        header: 'New',
        date: 'Apr 9, 2021',
        id: 123,
        status: 'Canceled'
      },
      {
        header: 'Refill',
        date: 'Apr 10, 2021',
        id: 124,
        status: 'Canceled'
      },
      {
        header: 'New',
        date: 'Apr 9, 2021',
        id: 123,
        status: 'Canceled'
      },
      {
        header: 'New',
        date: 'Apr 9, 2021',
        id: 123,
        status: 'Canceled'
      },
      {
        header: 'New',
        date: 'Apr 9, 2021',
        id: 123,
        status: 'Canceled'
      },
      {
        header: 'New',
        date: 'Apr 9, 2021',
        id: 123,
        status: 'Canceled'
      }
    ]
  })
}
</script>

<style scoped>

</style>
