const state = {
  step: 1,
  firstLogin: true,
  helpDialog: false
}

// getters
const getters = {
  step: state => state.step,
  firstLogin: state => state.firstLogin,
  helpDialog: state => state.helpDialog
}

const mutations = {
  SET_STEP (state, step) {
    state.step = step
  },
  SET_FIRST_LOGIN (state, firstLogin) {
    state.firstLogin = firstLogin
  },
  SET_HELP_DIALOG (state, helpDialog) {
    state.helpDialog = helpDialog
  }
}

// actions
const actions = {
  // reportLogout ({ commit }, msg) {
  //   // console.log(msg)
  //   Notification.notify({
  //     verticalAlign: 'top',
  //     horizontalAlign: 'right',
  //     type: 'danger',
  //     timeout: 0,
  //     message: msg.toString()
  //   })
  // }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
