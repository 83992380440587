<template>
  <div>
    <gg-input-label v-if="label" class="d-block pb-1">
      {{ label }}
    </gg-input-label>
    <v-text-field
      v-bind="$attrs"
      v-model="internalValue"
      v-on="$listeners"
      :label="''"
      dense
      outlined
      class="gg-input"
    >
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope"/>
      </template>
      <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    </v-text-field>
  </div>
</template>

<script>
import GGInputMixin from '@/mixins/ggInput'

export default {
  name: 'GGTextField',
  $_veeValidate: {
    value () {
      return this.value
    }
  },
  mixins: [GGInputMixin]
}
</script>
