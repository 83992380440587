<template>
  <div class='sidebar'>
    <div class='hidden-xs-only' style='padding-top: 75px'></div>
    <div class='hidden-sm-and-up' style='padding-top: 56px'></div>
    <vue-perfect-scrollbar class='inbox-sidebar-scroll ps-sidebar'
                           ref="scrollbar"
                           :settings="scrollbarSettings"
    >
      <div class="transparent navigation">
        <v-list expand dark>
          <template v-for='item in mainMenu'>

            <!-- submenu -->
            <template v-if='item.items'>
              <v-list-group :ripple="false"
                            :key='item.title'
                            prepend-icon=''
                            no-action append-icon=''
                            :group='item.group'
                            v-model='item.active'>
                <v-list-item slot='activator'
                             :ripple="false"
                             class="mb-1 menu-parent">
                  <v-list-item-content class="py-1">
                    <v-list-item-title @click="updateScrollbar">
                      <v-icon v-if='item.action' class='mr-1'>
                        {{ item.action }}
                      </v-icon>
                      <span :class="[item.active ? 'font-weight-bold' : 'font-weight-medium']">
                        {{ textTruncate(item.title) }}
                      </span>
                      <v-icon small class='mb-1 ml-1'>
                        {{ item.active ? 'mdi-menu-down' : 'mdi-menu-right' }}
                      </v-icon>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-avatar>
                  </v-list-item-avatar>
                </v-list-item>
                <template v-for='subItem in item.items.filter(i => !i.hidden)'>
                  <v-list-item
                    dark
                    v-if='!subItem.external'
                    :key='subItem.title'
                    :exact='subItem.exact'
                    :to="{ name: subItem.to }"
                    :ripple="false"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ textTruncate(subItem.title) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-else
                    dark
                    :key='subItem.title'
                    :exact='subItem.exact'
                    :ripple="false"
                    :href='subItem.to'
                    target='_blank'
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ textTruncate(subItem.title) }}
                        <v-icon small class='mb-1 ml-1'>mdi-open-in-new</v-icon>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list-group>
            </template>

            <!-- single menu item -->
            <template v-else>
              <v-list-item
                :to="item.to"
                :key='item.path'
                :exact='item.exact'
                :ripple="false"
                @click="menuClick(item.onClick)"
                class="mb-1"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <v-icon v-if='item.action' class='mr-2'>{{ item.action }}</v-icon>
                    <span class="fs-14 font-weight-medium">
                      {{ textTruncate(item.title) }}
                    </span>
                    <v-icon v-if="item.icon" small class="pl-2">
                      {{ item.icon }}
                    </v-icon>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </template>
        </v-list>
      </div>
    </vue-perfect-scrollbar>

    <v-dialog
      v-model="dialog"
      max-width="340"
    >
      <gg-card>
        <gg-card-header>
          Get Help
        </gg-card-header>
        <gg-card-text>
          Et condimentum netus condimentum eleifend.
        </gg-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <sp-btn secondary @click="dialog = false">
            Close
          </sp-btn>
        </v-card-actions>
      </gg-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { mainMenu } from '@/helpers/menu'
import { textTruncate } from '@/helpers/helpers'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  name: 'Sidebar',
  data: () => ({
    mainMenu,
    scrollbarSettings: {
      suppressScrollX: true,
      scrollXMarginOffset: 16
    }
  }),
  computed: {
    ...mapGetters({
      helpDialog: 'profile/helpDialog'
    }),
    dialog: {
      get () {
        return this.helpDialog
      },
      set (val) {
        this.setDialog(val)
      }
    }
  },
  components: {
    VuePerfectScrollbar
  },
  methods: {
    ...mapMutations({
      setDialog: 'profile/SET_HELP_DIALOG'
    }),
    textTruncate (text) {
      return textTruncate(text, 32)
    },
    updateScrollbar () {
      // wait until animation complete and update scrollbar
      setTimeout(() => {
        this.$refs.scrollbar.ps.update()
      }, 500)
    },
    delayedNavigateTo (to) {
      // programmatically navigate with 50ms delay to prevent submenu collapse
      // setTimeout(() => {
      this.$router.push(to)
      // }, 50)
    },
    menuClick (handler) {
      if (typeof handler !== 'function') return
      handler()
    }
  }
}
</script>

<style scoped lang="scss">
@media only screen and (max-width: 599px) {
  .ps-sidebar {
    height: 100vh;
  }
}

@media only screen and (min-width: 600px) {
  .ps-sidebar {
    height: calc(100vh - 75px);
  }
}

.menu-parent:before {
  background: none;
}
</style>
