<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="prescriptions"
      class="elevation-1"
      :items-per-page="10"
    >
      <template v-slot:item.manage="{ item }">
        <sp-btn
          small
          :to="{ name: 'admin-pending-prescription', params: { id: item.orderId } }"
        >
          Manage
        </sp-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PrescriptionsList',
  data () {
    return {
      headers: [
        { text: 'Order ID', value: 'orderId' },
        { text: 'Order Type', value: 'orderType' },
        { text: 'Source of Order', value: 'orderSource' },
        { text: 'Name', value: 'name' },
        { text: 'Phone Number', value: 'phone' },
        { text: '', value: 'manage' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      prescriptions: 'adminPrescriptions/pendingPrescriptions'
    })
  }
}
</script>
