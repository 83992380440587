<template>
  <div>
    <v-navigation-drawer v-if="horizontal"
                         app
                         fixed
                         v-model="drawer"
                         :width="250"
                         class="vuely-sidebar"
                         :color="colors.sidebar"
    >
      <template v-slot:prepend>
        <div class="d-flex pa-2 align-center justify-center">
          <router-link :to="{ name: 'dashboard' }">
            <img src="@/assets/img/logo.png"
                 height="48"
                 alt="logo"
            >
          </router-link>
        </div>
      </template>
      <!-- App Sidebar -->
      <app-sidebar />
    </v-navigation-drawer>

    <v-app-bar app flat class="vuely-toolbar" :color="colors.content">
      <div class="hidden-lg-and-up navbar-left align-items-center pl-2">
        <v-btn icon @click.stop="drawer = !drawer" class="pl-0 ml-0">
          <v-icon color="#69737C">mdi-menu</v-icon>
        </v-btn>
      </div>
      <v-spacer />
      <div class="navbar-right">
        <user-block />
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import Sidebar from '@/components/General/Sidebar/Sidebar.vue'
import UserBlock from '@/components/General/Sidebar/UserBlock'

import { mapGetters } from 'vuex'

export default {
  data: () => ({
    collapsed: false, // collapse sidebar
    drawer: null, // sidebar drawer default true
    chatSidebar: false, // chat component right sidebar
    sidebarImages: '', // sidebar background images
    enableDefaultSidebar: false
  }),
  props: {
    horizontal: {
      default: true,
      type: Boolean
    },
    colors: {
      type: Object
    }
  },
  components: {
    appSidebar: Sidebar,
    UserBlock
  },
  computed: {
    // ...mapGetters(['brand', 'theme']),
    ...mapGetters({
      brand: 'settings/brand',
      theme: 'settings/theme'
    }),
    headerColor () {
      return this.$vuetify.theme.dark ? this.theme.dark.header : this.theme.light.header
    }
  }
}
</script>
