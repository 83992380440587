import Vue from 'vue'
import { VueMaskDirective } from 'v-mask'
import Notifications from 'vue-notification'

import App from './App.vue'
import router from './router'
import { store } from './store'
import vuetify from './plugins/vuetify'
import globalComponents from '@/plugins/globalComponents'

import '@/lib/VuelyCss'
import '@mdi/font/css/materialdesignicons.css'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import './plugins/vee-validate'

Vue.config.productionTip = false

Vue.directive('mask', VueMaskDirective)

// Notifications
Vue.use(Notifications)
Vue.prototype.$notifySuccess = function (message) {
  const text = typeof message === 'string' ? message : message?.resultmsg
  Vue.notify({
    text,
    duration: 10000,
    type: 'success'
  })
}
Vue.prototype.$notifyError = function (message) {
  const text = typeof message === 'string' ? message : message?.resultmsg
  Vue.notify({
    text,
    duration: 10000,
    type: 'error'
  })
}

// vee validate
Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)

new Vue({
  router,
  store,
  vuetify,
  globalComponents,
  render: h => h(App)
}).$mount('#app')
