<template>
  <v-container class="px-6 pl-md-10" style="margin: initial">
    <pending-prescription />
  </v-container>
</template>

<script>
import PendingPrescription from '@/components/Admin/Prescriptions/PendingPrescription'

export default {
  name: 'PendingPrescriptionView',
  components: {
    PendingPrescription
  }
}
</script>
