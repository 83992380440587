<template>
  <gg-card height="100%">
    <gg-card-header>Prescription Order Details</gg-card-header>
    <gg-card-text>
      <div class="pb-2">
        <span class="pr-2">Type:</span>
        <span>{{ prescription.orderType }}</span>
      </div>
      <div class="pb-2">
        <span class="pr-2">Details:</span>
        <span>Order details</span>
      </div>
      <div class="pb-2">
        <span class="pr-2">Prescription Copy:</span>
        <span>Mailed to SimplyPills</span>
      </div>
    </gg-card-text>
  </gg-card>
</template>

<script>
export default {
  name: 'OrderDetails',
  props: {
    prescription: {
      type: Object,
      required: true
    }
  }
}
</script>
