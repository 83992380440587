import { extend } from 'vee-validate'
import { digits, required, email, numeric } from 'vee-validate/dist/rules'
import PhoneNumber from 'awesome-phonenumber'
import isMatch from 'date-fns/isMatch'

extend('required', {
  ...required,
  message: 'This field is required'
})

extend('email', {
  ...email,
  message: 'This field must be a valid email'
})

extend('numeric', {
  ...numeric,
  message: 'This field may only contain numeric characters'
})

extend('phoneDigits', {
  ...digits,
  message: 'This field may only contain phone number'
})

extend('date', {
  message: 'This field should be a valid date',
  validate (value, args) {
    return isMatch(value, args.format)
  },
  params: ['format']
})

const phoneNumber = {
  message: 'This field should be a valid phone number',
  validate (value) {
    return new Promise(resolve => {
      const phone = new PhoneNumber(value)
      resolve({ valid: phone.isValid() })
    })
  }
}

extend('phone', phoneNumber)
