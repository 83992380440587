export default {
  model: {
    event: 'input',
    prop: 'value'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      lazyValue: this.value
    }
  },
  computed: {
    internalValue: {
      get () {
        return this.lazyValue
      },
      set (val) {
        this.lazyValue = val
        this.$emit('input', val)
      }
    },
    showError () {
      return this.$attrs['error-messages'].length > 0
    }
  }
}
