/**
 * Vuely Global Components
 */
import GGCard from '@/components/Global/Card/GGCard'
import GGCardHeader from '@/components/Global/Card/GGCardHeader'
import GGCardText from '@/components/Global/Card/GGCardText'

import GGTextField from '@/components/Global/Inputs/GGTextField'
import GGSelect from '@/components/Global/Inputs/GGSelect'
import GGInputLabel from '@/components/Global/Inputs/GGInputLabel'

import SpButton from '@/components/Global/SpButton'
import TextField from '@/components/Common/TextField'
import CardLine from '@/components/Common/CardLine'

const GlobalComponents = {
  install (Vue) {
    // card
    Vue.component('GgCard', GGCard)
    Vue.component('GgCardHeader', GGCardHeader)
    Vue.component('GgCardText', GGCardText)

    // inputs
    Vue.component('GgTextField', GGTextField)
    Vue.component('GgSelect', GGSelect)
    Vue.component('GgInputLabel', GGInputLabel)

    Vue.component('SpBtn', SpButton)

    Vue.component('SpTextField', TextField)

    Vue.component('CardLine', CardLine)
  }
}

export default GlobalComponents
