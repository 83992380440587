import Vue from 'vue'
import VueRouter from 'vue-router'

import { store } from '@/store'

import adminRoutes from './admin'

import ClientLayout from '@/container/Client'
import LoginLayout from '@/container/Login'

import ClientHome from '@/views/client/Home.vue'
import Onboarding from '@/views/client/Onboarding.vue'
import Prescriptions from '@/views/client/Prescriptions'

Vue.use(VueRouter)

const routes = [
  ...adminRoutes,
  {
    path: '/',
    component: ClientLayout,
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: ClientHome,
        meta: {
          accessType: 'auth'
        }
      },
      {
        path: 'transfer',
        name: 'transfer-refills',
        component: () => import(/* webpackChunkName: "patient" */ '@/views/client/dashboard/TransferRefillsView'),
        meta: {
          accessType: 'auth'
        }
      },
      {
        path: 'new-prescription',
        name: 'new-prescription',
        component: () => import(/* webpackChunkName: "patient" */ '@/views/client/dashboard/NewPrescriptionView'),
        meta: {
          accessType: 'auth'
        }
      },
      {
        path: 'refill-prescription',
        name: 'refill-prescription',
        component: () => import(/* webpackChunkName: "patient" */ '@/views/client/dashboard/RefillPrescriptionView'),
        meta: {
          accessType: 'auth'
        }
      },
      {
        path: 'search-prices',
        name: 'search-prices',
        component: () => import(/* webpackChunkName: "patient" */ '@/views/client/dashboard/SearchPrices'),
        meta: {
          accessType: 'auth'
        }
      },
      {
        path: 'doctor',
        name: 'see-doctor',
        component: () => import(/* webpackChunkName: "patient" */ '@/views/client/dashboard/SeeDoctorView'),
        meta: {
          accessType: 'auth'
        }
      },
      {
        path: '/onboarding',
        name: 'onboarding',
        component: Onboarding,
        meta: {
          accessType: 'auth'
        }
      },
      {
        path: '/prescriptions',
        name: 'prescriptions',
        component: Prescriptions,
        meta: {
          accessType: 'auth'
        }
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/client/profile/ProfileView'),
        meta: {
          accessType: 'auth'
        }
      }
    ]
  },
  {
    path: '/auth',
    component: LoginLayout,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/LoginView')
      },
      {
        path: 'registration',
        name: 'registration',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/RegistrationView')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/**
 * Auth protection
 */
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['session/isAuthenticated']
  const authRoutes = ['login', 'registration']
  if (to.meta.accessType === 'auth' && !isAuthenticated) {
    return next({ name: 'login' })
  }
  if (authRoutes.indexOf(to.name) !== -1 && isAuthenticated) {
    return next({ name: 'dashboard' })
  }
  return next()
})

export default router
