<template>
  <v-row>
    <v-col cols="12" sm="6">
      <patient-profile />
    </v-col>
    <v-col cols="12" sm="6">
      <order-details :prescription="prescription" />
    </v-col>
    <v-col cols="12" sm="6">
      <v-row class="d-flex flex-column">
        <v-col cols="12">
          <health-information />
        </v-col>
        <v-col cols="12">
          <insurance-information />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6">
      <uploaded-documents />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

import PatientProfile from './Pending/PatientProfile'
import OrderDetails from './Pending/OrderDetails'
import HealthInformation from './Pending/HealthInformation'
import InsuranceInformation from './Pending/InsuranceInformation'
import UploadedDocuments from './Pending/UploadedDocuments'

export default {
  name: 'PendingPrescription',
  components: {
    PatientProfile,
    OrderDetails,
    HealthInformation,
    InsuranceInformation,
    UploadedDocuments
  },
  computed: {
    ...mapGetters({
      pendingPrescriptions: 'adminPrescriptions/pendingPrescriptions'
    }),
    prescription () {
      return this.pendingPrescriptions.find(p => {
        return p.orderId === this.$route.params.id
      })
    }
  },
  mounted () {
    if (!this.prescription) {
      this.$router.replace({ name: 'admin-pending-prescriptions' })
    }
  }
}
</script>
