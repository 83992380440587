<template>
  <gg-card>
    <v-row>
      <v-col cols="12" md="4">
        <v-img :src="require('@/assets/img/pexels-anna-shvets-3683056.jpg')" />
      </v-col>
      <v-col cols="12" md="8">
        <validation-observer ref="validator">
          <sp-text-field
            outlined
            dense
            label="Name"
            rules="required"
            name="name"
            v-model="name"
          />
          <sp-text-field
            outlined
            dense
            label="Street Address 1"
            rules="required"
            name="address1"
            v-model="address1"
          />
          <sp-text-field
            outlined
            dense
            label="Street Address 2"
            name="address2"
            v-model="address2"
          />
          <v-row>
            <v-col cols="12" md="4">
              <sp-text-field
                outlined
                dense
                label="City"
                rules="required"
                name="city"
                v-model="city"
              />
            </v-col>
            <v-col cols="12" md="4">
              <validation-provider rules="required" v-slot="{ errors }">
                <gg-select
                  outlined
                  dense
                  label="Province"
                  v-model="province"
                  :items="[{ value: 'Ontario', text: 'Ontario' }]"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" md="4">
              <sp-text-field
                outlined
                dense
                label="ZIP"
                rules="required"
                name="zip"
                v-model="zip"
              />
            </v-col>
          </v-row>
          <v-checkbox label="Make this my default address" dense hide-details></v-checkbox>
        </validation-observer>
      </v-col>
    </v-row>
    <v-card-actions class="justify-content-end">
      <sp-btn secondary @click="setStep(2)">Back</sp-btn>
      <sp-btn @click="save">Save</sp-btn>
    </v-card-actions>
  </gg-card>
</template>

<script>
export default {
  name: 'AddressCard',
  data () {
    return {
      name: '',
      address1: '',
      address2: '',
      city: '',
      province: '',
      zip: '',
      defaultAddress: false
    }
  },
  methods: {
    setStep (val) {
      this.$emit('switch-step', val)
    },
    async save () {
      const valid = await this.$refs.validator.validate()
      if (!valid) {
        return
      }
      this.setStep(4)
    }
  }
}
</script>

<style scoped>

</style>
