/**
 * Text truncate
 * @param str {string} - Text to truncate
 * @param length {number} - String length
 * @param ending {string} - End of truncated string
 * @returns str {string} - Formatted string
 */
export function textTruncate (str, length = 100, ending = '...') {
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending
  } else {
    return str
  }
}

/**
 * Check if api response contains an error.
 * @param response Object
 * @returns Boolean
 */
export function responseFailed (response) {
  if (typeof response !== 'object') {
    throw new Error('Invalid response.')
  }
  return response.result !== 'success'
}

/**
 * Get the error message from api response.
 * @param response Object
 * @returns String
 */
export function responseErrorMessage (response) {
  const defaultMsg = 'Oops! Something went wrong.'
  if (typeof response !== 'object') return defaultMsg
  return response.resultmsg || defaultMsg
}

/**
 * Get the error message from api response if response failed.
 * @param response Object
 * @returns String, Boolean
 */
export function responseError (response) {
  if (responseFailed(response)) {
    return responseErrorMessage(response)
  }
  return false
}
