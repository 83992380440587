<template>
  <v-card-title>
    <v-row>
      <v-col cols="12" class="pb-0">
        <slot></slot>
      </v-col>
      <v-col cols="12" class="pa-0 gg-card-divider">
        <v-divider class="gg-card-divider"></v-divider>
      </v-col>
    </v-row>
  </v-card-title>
</template>

<script>
export default {
  name: 'GGCardHeader'
}
</script>
