<template>
  <div>
    <h3 class="font-weight-medium pb-6">
      Patient Dashboard
    </h3>
    <v-item-group active-class="active" v-model="selected">
      <v-container>
        <v-row>
          <v-col v-for="(item, key) in items" :key="key" cols="12" md="3" class="py-0 pb-1 px-1">
            <v-item v-if="item.value" v-slot="{ active, toggle }" :value="item.value">
              <v-card class="select-item d-flex  align-center" height="200" @click="toggle">
                <div class="pb-4">
                  <v-icon size="60" :color="active ? 'black' : 'grey'">
                    {{ item.icon }}
                  </v-icon>
                </div>
                <div class="title">
                  {{ item.title }}
                </div>
                <div class="body-2 text-center px-1">
                  {{ item.description }}
                </div>
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-container>
    </v-item-group>
  </div>
</template>

<script>
export default {
  name: 'PatientDashboard',
  data () {
    return {
      items: [
        {
          title: 'Transfer Prescription',
          icon: 'mdi-file-swap-outline',
          description: 'We’ll transfer your existing refills from your current pharmacy.',
          value: 'transfer-refills'
        },
        {
          title: 'New Prescription',
          icon: 'mdi-text-box-plus-outline',
          description: 'Let us fill your new prescription and have it delivered to you.',
          value: 'new-prescription'
        },
        {
          title: 'Refill Prescription',
          icon: 'mdi-text-box-check-outline',
          description: 'Let us handle your prescription refills.',
          value: 'refill-prescription'
        },
        {}, // SP-21: Move Search Drug Prices & See a Doctor below the 3 top boxes.
        {
          title: 'Search Drug Prices',
          icon: 'mdi-shopping-search',
          description: 'Search for prices on medication.',
          value: 'search-prices'
        },
        {
          title: 'See a Doctor',
          icon: 'mdi-account-check-outline',
          description: 'See a doctor for free, and get your prescription online.',
          value: 'see-doctor'
        }
      ],
      selected: ''
    }
  },
  watch: {
    selected (val) {
      this.$router.push({
        name: val
      })
    }
  },
  computed: {
    itemMd () {
      return Math.ceil(12 / this.items.length)
    }
  },
  methods: {
    back () {
      this.selected = ''
    }
  }
}
</script>
