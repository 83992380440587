<template>
  <gg-card>
    <v-row>
      <v-col cols="12" md="4">
        <v-img :src="require('@/assets/img/pexels-anna-shvets-3683056.jpg')" />
      </v-col>
      <v-col cols="12" md="8">
        <validation-observer ref="validator">
          <v-row>
            <v-col cols="12">
              <sp-text-field
                v-model="cardNum"
                rules="required"
                outlined
                dense
                label="Card Number"
                placeholder="XXXX-XXXX-XXXX-XXXX"
                name="CardNumber"
                v-mask="'####-####-####-####'"
                append-icon="mdi-credit-card"
                autocomplete="new-password"
              />
            </v-col>
            <v-col cols="12" md="4">
              <sp-text-field
                v-model="expDate"
                rules="required"
                outlined
                dense
                label="Expiry Date"
                placeholder="MM/YY"
                v-mask="'##/##'"
                autocomplete="new-password"
              />
            </v-col>
            <v-col cols="12" md="4">
              <sp-text-field
                v-model="cvv"
                rules="required"
                label="CVV/CVC"
                type="password"
                outlined
                dense
                autocomplete="new-password"
              />
            </v-col>
          </v-row>
        </validation-observer>
      </v-col>
    </v-row>
    <v-card-actions class="justify-content-end">
      <sp-btn secondary @click="setStep(3)">Back</sp-btn>
      <sp-btn secondary @click="setStep(5)">Skip this for now</sp-btn>
      <sp-btn @click="save">Save</sp-btn>
    </v-card-actions>
  </gg-card>
</template>

<script>
export default {
  name: 'PaymentMethod',
  data () {
    return {
      cardNum: '',
      expDate: '',
      cvv: ''
    }
  },
  methods: {
    setStep (val) {
      this.$emit('switch-step', val)
    },
    async save () {
      const valid = await this.$refs.validator.validate()
      if (!valid) {
        return
      }
      this.setStep(5)
    }
  }
}
</script>

<style scoped>

</style>
