<template>
  <v-btn
    elevation="0"
    class="gg-button text-capitalize"
    v-bind="mergedAttrs"
    v-on="$listeners"
  >
    <slot v-for="(_, name) of $slots" :name="name" :slot="name" />
  </v-btn>
</template>

<script>
export default {
  name: 'SpBtn',
  props: {
    primary: {
      type: Boolean,
      default: true
    },
    secondary: {
      type: Boolean,
      default: false
    },
    tertiary: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      primaryAttrs: {
        tile: false,
        dark: true
      },
      secondaryAttrs: {
        outlined: true,
        tile: false
      },
      tertiaryAttrs: {
        elevation: 0,
        tile: false,
        plain: true
      }
    }
  },
  computed: {
    mergedAttrs () {
      let attrs = {}
      if (this.secondary) {
        attrs = this.secondaryAttrs
      } else if (this.tertiary) {
        attrs = this.tertiaryAttrs
      } else if (this.primary) {
        attrs = this.primaryAttrs
      }
      // set default color
      if (!this.$attrs.color) {
        attrs.color = '#1887bf'
      }
      // return merged attrs
      return {
        ...this.$attrs,
        ...attrs
      }
    }
  }
}
</script>
