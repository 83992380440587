<template>
  <gg-card>
    <v-row>
      <v-col cols="12" md="4">
        <v-img :src="require('@/assets/img/pexels-anna-shvets-3683056.jpg')" />
      </v-col>
      <v-col cols="12" md="8">
        <v-radio-group label="Do you have any allergies to any medications" v-model="haveAllergies">
          <v-radio label="Yes" :value="true"></v-radio>
          <v-radio label="No" :value="false"></v-radio>
        </v-radio-group>
        <template v-if="haveAllergies">
          <sp-text-field
            label="Allergies"
            v-model="allergies"
          />
        </template>
        <gg-input-label>
          Tell us your current prescription medications
        </gg-input-label>
        <v-textarea v-model="medications" outlined dense rows="2" />
        <gg-input-label>
          Tell us about any medical conditions you may have
        </gg-input-label>
        <v-textarea v-model="medicalConditions" outlined dense rows="2" />
        <gg-input-label>
          Enter any over-the-counter supplements and/or medications that you are taking
        </gg-input-label>
        <v-textarea v-model="supplements" outlined dense rows="2" />
      </v-col>
    </v-row>
    <v-card-actions class="justify-content-end">
      <sp-btn secondary>Cancel</sp-btn>
      <sp-btn @click="complete">Complete</sp-btn>
    </v-card-actions>
  </gg-card>
</template>

<script>
export default {
  name: 'HealthInformation',
  data () {
    return {
      haveAllergies: null,
      allergies: '',
      supplements: '',
      medications: '',
      medicalConditions: ''
    }
  },
  methods: {
    setStep (val) {
      this.$emit('switch-step', val)
    },
    complete () {
      this.$router.push({ name: 'prescriptions' })
    }
  }
}
</script>

<style scoped>

</style>
