import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import PrescriptionStore from '@/store/modules/prescription/prescription'
import ProfileStore from '@/store/modules/profile/profile'
import SessionStore from '@/store/modules/session/session'
import SettingsStore from '@/store/modules/settings/settings'

import AdminPrescriptions from './modules/admin/prescriptions'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    prescription: PrescriptionStore,
    profile: ProfileStore,
    session: SessionStore,
    settings: SettingsStore,
    adminPrescriptions: AdminPrescriptions
  },
  plugins: [
    createPersistedState({
      paths: ['session']
    })
  ],
  strict: process.env.NODE_ENV !== 'production'
})
