<template>
  <div>
    <gg-input-label v-if="label" class="d-block pb-1">
      {{ label }}
    </gg-input-label>
    <v-select
      v-bind="$attrs"
      v-on="$listeners"
      v-model="internalValue"
      :label="''"
      dense
      outlined
      class="gg-input"
    >
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope"/>
      </template>
      <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'GGSelect',
  $_veeValidate: {
    value () {
      return this.value
    }
  },
  model: {
    event: 'input',
    prop: 'value'
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      lazyValue: this.value
    }
  },
  computed: {
    internalValue: {
      get () {
        return this.lazyValue
      },
      set (val) {
        this.lazyValue = val
        this.$emit('input', val)
      }
    },
    showError () {
      return this.$attrs['error-messages'].length > 0
    }
  }
}
</script>
