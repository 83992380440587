import axiosBase from './axios-base'
import { store } from '@/store'
import { responseError } from '@/helpers/helpers'
import qs from 'qs'

const baseURL = process.env.VUE_APP_BASE_URL ?? 'https://api.simplypills.com/'

export default {
  async makecall (method, params = {}, noErrorCheck = false) {
    if (method !== 'reauth') {
      if (store?.getters?.sid) { params.sid = store.getters.sid }
    }

    params.r_x = Math.round(Math.random() * 10000)
    params.r_y = Math.round(Math.random() * 10000)
    params.r_z = (params.r_x * params.r_y) - 1621

    let response
    try {
      response = await axiosBase({
        method: 'post',
        baseURL,
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        url: method,
        data: qs.stringify(params)
      })
    } catch (err) {
      console.warn('API network error', err)
      throw err
    }
    if (response.status === 201) {
      if (noErrorCheck) {
        return response.data
      }
      if (response.data?.http_response_code === 401) {
        // handle session expired responses and clear sid
        let message
        try {
          await store.dispatch('attemptLogout')
          await store.dispatch('reportLogout', 'You were automatically logged out due to inactivity. Please re-login.')
        } catch (err) {
          console.warn('amapi attemptLogout error', err)
          message = err.message
        }
        // router.push({ name: 'login' }) // authrecheck is already doing this redirection
        if (!message) {
          message = response.data?.resultmsg || 'Session expired! Please, login to continue.'
        }
        throw new Error(message)
      }
      if (!responseError(response.data)) {
        // Set api version to store
        return response.data
      } else {
        throw responseError(response.data)
      }
    }
  }
}
