<template>
  <div>
    <h3 class="font-weight-medium pb-6">
      Welcome to SimplyPills, let’s begin.
    </h3>
    <div>
      <v-item-group active-class="active" v-model="selected">
        <v-row no-gutters>
          <v-col v-for="(item, key) in items" :key="key" cols="12" md="4" class="py-0 py-xs-0 py-1 px-1">
            <v-item v-slot="{ active, toggle }" :value="item.value">
              <v-card class="select-item d-flex align-center" height="200" @click="toggle">
                <div class="pb-4">
                  <v-icon size="60" :color="active ? 'black' : 'grey'">
                    {{ item.icon }}
                  </v-icon>
                </div>
                <div class="title">
                  {{ item.title }}
                </div>
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-item-group>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'PrescriptionsMenu',
  data: () => ({
    items: [
      {
        title: 'Transfer Your Prescription(s)',
        icon: 'mdi-check-circle-outline',
        value: 'transfer'
      },
      {
        title: 'Refill your Prescriptions(s)',
        icon: 'mdi-check-circle-outline',
        value: 'refill'
      },
      {
        title: 'I have new Prescription(s)',
        icon: 'mdi-check-circle-outline',
        value: 'new'
      }
    ]
  }),
  computed: {
    ...mapGetters({
      selectedOption: 'prescription/selectedOption',
      step: 'prescription/step'
    }),
    selected: {
      get () {
        return this.selectedOption
      },
      set (val) {
        this.SELECT_PRESCRIPTION_TYPE(val)
        this.step = 1
      }
    },
    step: {
      get () {
        return this.step
      },
      set (val) {
        this.SET_STEP(val)
      }
    },
    isValueSelected () {
      return this.selected !== undefined
    }
  },
  methods: {
    ...mapMutations({
      SET_STEP: 'prescription/SET_STEP',
      SELECT_PRESCRIPTION_TYPE: 'prescription/SELECT_PRESCRIPTION_TYPE'
    })
  }
}
</script>

<style scoped>

</style>
