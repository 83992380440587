import apiClient from '@/api/api'
import { getCookie, setCookie, removeCookie } from 'tiny-cookie'

const state = {
  profile: {},
  sid: null,
  session_ready: false
}

const getters = {
  session_ready: state => state.session_ready,
  sid: state => state.sid,
  isAuthenticated: state => !!state.sid
}

const mutations = {
  SET_SID (state, { sid }) {
    if (sid !== null && sid !== undefined) {
      setCookie('sid', sid, { expires: '7D', SameSite: 'None', Secure: true })
      state.sid = sid
    } else {
      removeCookie('sid')
      state.sid = null
    }
  },
  SET_SESSION (state, session) {
    const accountId = session.AccountId
    state.profile = {
      ...session.profile,
      accountId
    }
    state.session_notice = session.session_notice
  },
  SET_PROFILE (state, profile) {
    state.profile = {
      ...state.profile,
      ...profile
    }
  },
  SET_SESSION_READY (state, status) {
    state.session_ready = status
  }
}

const actions = {
  async initializeSession ({ commit, dispatch }) {
    const sid = getCookie('sid')
    if (sid !== null) {
      commit('SET_SID', { sid: sid })
      try {
        await dispatch('getProfile')
      } catch (err) {
        // dispatch('reportError', err) // report user about the network error
        commit('SET_SESSION_READY', true)
        return
      }
      commit('SET_SESSION_READY', true)
    } else {
      commit('SET_SID', { sid: null })
      commit('SET_SESSION_READY', true)
    }
  },
  async login ({ dispatch, commit }, params) {
    if (params.sid) {
      const response = await apiClient.makecall('reauth', { sid: params.sid })
      if (response.sid) {
        commit('SET_SID', { sid: response.sid, reauth: true })
        await dispatch('initializeSession')
        return response
      } else {
        throw response
      }
    } else {
      const response = await apiClient.makecall('trylogin', params)
      if (response.sid) {
        commit('SET_SID', { sid: response.sid })
        await dispatch('initializeSession')
        return response
      } else {
        return response
      }
    }
  },
  logout ({ commit }) {
    commit('SET_SID', { sid: null })
  },
  // registration:
  async enroll ({ dispatch, commit }, params) {
    const response = await apiClient.makecall('enroll', params)
    if (response.sid) {
      commit('SET_SID', { sid: response.sid })
    }
    return response
  },
  async getProfile ({ commit }) {
    try {
      // const response = await apiClient.makecall('getsession')
      // commit('SET_SESSION', response)
    } catch (err) {
      commit('SET_SID', { sid: null }) // AM-153
      throw err
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
