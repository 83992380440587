<template>
  <gg-card height="100%">
    <gg-card-header>
      Uploaded Documents
    </gg-card-header>
    <gg-card-text></gg-card-text>
  </gg-card>
</template>

<script>
export default {
  name: 'UploadedDocuments'
}
</script>
