<template>
  <v-container class="px-6 pl-md-10" style="margin: initial">
    <v-row>
      <template v-if="firstLogin">
        <template v-if="!prescriptionCompleted">
          <v-col v-if="step === 0" cols="12">
            <v-slide-y-transition hide-on-leave>
              <prescriptions-menu />
            </v-slide-y-transition>
          </v-col>
          <v-col v-if="step === 1" cols="12">
            <h3 class="font-weight-medium pb-6">
              {{ stepTitle }}
            </h3>
            <v-slide-y-transition hide-on-leave>
              <component :is="componentType" @back="back" />
            </v-slide-y-transition>
          </v-col>
        </template>
        <v-col v-else cols="12">
          <complete-profile />
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import PrescriptionsMenu from '@/components/Prescriptions/PrescriptionsMenu/PrescriptionsMenu'
import CompleteProfile from '@/components/CompleteProfile/CompleteProfile'

export default {
  name: 'Onboarding',
  components: {
    PrescriptionsMenu,
    CompleteProfile
  },
  computed: {
    ...mapGetters({
      step: 'prescription/step',
      selectedOption: 'prescription/selectedOption',
      prescriptionCompleted: 'prescription/prescriptionCompleted',
      firstLogin: 'profile/firstLogin',
      theme: 'settings/theme'
    }),
    componentType () {
      switch (this.selectedOption) {
        case 'transfer':
          return () => import('@/components/Prescriptions/PrescriptionItems/Transfer')
        case 'refill':
          return () => import('@/components/Prescriptions/PrescriptionItems/Refill')
        case 'new':
          return () => import('@/components/Prescriptions/PrescriptionItems/New')

        default:
          return ''
      }
    },
    stepTitle () {
      switch (this.selectedOption) {
        case 'transfer':
          return 'Transfer Your Prescription'
        case 'refill':
          return 'Order Refills'
        case 'new':
          return 'Submit New Prescription'

        default:
          return ''
      }
    }
  },
  methods: {
    ...mapMutations({
      setStep: 'prescription/SET_STEP'
    }),
    back () {
      this.setStep(0)
    }
  }
}
</script>

<style lang="scss">
.select-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.active {
  border: 4px solid #00A1BB !important;;
}
</style>
