<template>
  <div class="app-default-layout">
    <v-main evelation="0">
      <router-view />
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LoginLayout',
  data () {
    return {
      loading: true
    }
  },
  computed: {
    ...mapGetters({
      theme: 'settings/theme'
    })
  }
}
</script>

<style>
.app-default-layout {
  height: 100vh;
}
.v-btn--contained {
  box-shadow: none !important; /** AM-695 remove btn shadow */
}
</style>
